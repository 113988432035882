import React, {Component} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux';
import SEO from '../components/seo'
import { tablet, desktop } from '../breakpoints';
import archive1 from '../images/archive/archive_1.jpg';
import archive2 from '../images/archive/archive_2.jpg';
import archive3 from '../images/archive/archive_3.jpg';
import archive4 from '../images/archive/archive_4.jpg';
import archive5 from '../images/archive/archive_5.jpg';
import archive6 from '../images/archive/archive_6.jpg';
import archive7 from '../images/archive/archive_7.jpg';
import archive8 from '../images/archive/archive_8.jpg';
import archive9 from '../images/archive/archive_9.jpg';
import archive10 from '../images/archive/archive_10.jpg';
import archive11 from '../images/archive/archive_11.jpg';
import archive12 from '../images/archive/archive_12.jpg';
import archive13 from '../images/archive/archive_13.jpg';
import archive15 from '../images/archive/archive_15.jpg';
import archive16 from '../images/archive/archive_16.png';
import studio from '../images/studio.jpg';
import brands from '../images/brands.jpg';

class LivePage extends Component {

	constructor(){
		super();
        this.state = {
            videoPlaying: false
        }
	}

	componentDidMount(){

	

	}

	
	componentWillUnmount(){
	
	}

	/*
    |--------------------------------------------------------------------------
    | watch for prop updates
    |--------------------------------------------------------------------------
    */
   	componentWillReceiveProps(next){
		
	}

	render() { 

	
		return (
			<Page>

				<SEO title="Archive" keywords={[`radio`, `award winning`, `production`]} />

				<Wrapper>
					<Title>Archive</Title>
					
                    <Banner>                  
                        <div className="inner"></div>
                    </Banner>
				</Wrapper>
               
                

                <LiveInfo>
					

					<Inner>
                        
					<div className="intro inner">
                            <p>A selection of some of the ground breaking radio shows and podcasts we are proud to have worked on since 2003.</p>
                        </div>
						
					<Container>
							<Left>
								<IntroPics>
                	<img src={archive1} />
									<Divider/>
									<Subtitle>NHS <span>Bedroom Business Podcast</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive9} />
									<Divider/>
									<Subtitle>The Beatport 20</Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive10} />
									<Divider/>
									<Subtitle>Carl Cox <span>Global</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive15} />
									<Divider/>
									<Subtitle>David Guetta <span> F*** Me I'm Famous</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive2} />
									<Divider/>
									<Subtitle>Sander van Doorn <span>Identity</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive3} />
									<Divider/>
									<Subtitle>Nicky Romero <span>Protocol</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive11} />
									<Divider/>
									<Subtitle>Scuba <span>SCB Radio</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive12} />
									<Divider/>
									<Subtitle>Gorgon City <span>Kingdom</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive4} />
									<Divider/>
									<Subtitle>Eats Everything <span>Edible Beats</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive13} />
									<Divider/>
									<Subtitle>Chris Liebing <span>AM:FM</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive5} />
									<Divider/>
									<Subtitle>ABSOLUTE. <span>Global Residency</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive6} />
									<Divider/>
									<Subtitle>DJ Seinfeld <span>Global Residency</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive7} />
									<Divider/>
									<Subtitle>Real Lies <span>Global Residency</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive8} />
									<Divider/>
									<Subtitle>Pendulum <span>Global Residency</span></Subtitle>
                </IntroPics>
							</Left>

							<Left>
								<IntroPics>
                	<img src={archive16} />
									<Divider/>
									<Subtitle>DJ EZ <span>NUVOLVE</span></Subtitle>
                </IntroPics>
							</Left>
					</Container>
				</Inner>
			</LiveInfo>
		</Page>
		)

  }

}

function mapStateToProps(state){
	return { 
	
	}
}

export default connect(mapStateToProps, {  })(LivePage);


/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Page = styled.section`
	min-height:100%;
`

const Wrapper = styled.section`
	padding-top:100px;
	background:#c2b0bc;
`

const LiveInfo = styled.section`
	min-height:100%;
	padding-top:40px;
    background:rgb(253, 247, 244);
    color:#000;
    font-size:22px;
	line-height:1.6em;	

    .inner {
        padding:0 40px 40px 40px;
    }

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		font-size:18px;
	 }	 
`
const Banner = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    position: relative;
    padding-top: 25px;
	padding-bottom: 40px;

	@media only screen and (max-width: ${tablet}) {
		padding-bottom:0;
	 }	
	
	img {
		max-width:100%;
	}

    .inner {
        padding:0 40px 40px 40px;
    }
`

const Title = styled.h1`
	font-size: 7vw;
	line-height:1em;
    letter-spacing:0.01em;
	margin:0;
	margin-bottom:30px;
	font-weight:normal;
	text-align:center;

	@media only screen and (max-width: ${tablet}) {
		font-size:40px;
		padding:0 40px;
		margin-bottom:0;
	 }

	 @media only screen and (min-width: ${desktop}) {
		font-size:100px;
	 }
`

const Container = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
		min-height:100%;
		display:flex;
		flex-wrap: wrap;
		justify-content: flex-start;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column-reverse;
		padding-bottom:0;
	 }
`

const ContainerSecond = styled.section`
    padding:20px;
    padding-top:0;
    padding-bottom:20px;
	min-height:100%;
	display:flex;

	@media only screen and (max-width: ${tablet}) {
		flex-flow: column;
		padding-bottom:0;
     }
     
    
`

const IntroPics = styled.div`
     padding-top:10px;

     img {
         max-width:100%;
         margin-bottom:20px;
     }

     img:last-child {
         margin-bottom:0;
     }
`

const Inner = styled.div`
	max-width:1100px;
	width:100%;
    margin:auto;

    .intro {
        p {
            font-size: 30px;
            line-height: 1.5em;

            @media only screen and (max-width: ${tablet}) {
                font-size:25px;
             }	
        }
    }
`

const Left = styled(Container)`
	width:25%;
	padding-right:2.5%;
  position:relative;
  display:block;

	@media only screen and (max-width: ${tablet}) {
		width:100%;
		padding:10px;
        padding-bottom:40px;
	 }

	 .imageWrapper {
		overflow: hidden;
		position: absolute;
		top: 20px;
		left: 20px;
		right: 0;
		bottom: 0;
		width: 90%;
		height: 100%;
		z-index:100;
		transition: 1.2s ease;
	 }

	 .imageWrapper.outToLeft {
		 width: 0%;
		 height:100% !important;
		 transition: width 1.2s ease;
	 }

	 .imageWrapper.outFromBottom {
		height: 0%;
		transition: height 1.2s ease;
	}

	 .gatsby-image-wrapper {
		width: 100%;
		margin-top:10px;

		@media only screen and (max-width: ${tablet}) {
			width:100% !important;
			
		 }

		img {
			height:auto !important;
			max-width:411px;

			@media only screen and (max-width: ${tablet}) {
				
				width:350px !important;
			 }
		}
	}
`

const Subtitle = styled.h2`
    font-family:'EuclidFlex';
    font-weight:normal;
    font-size: 24px;

		span { 
			display: block;
			opacity: .7;
			font-size: 18px;
			line-height: 1.2em;
		}
`

const Divider = styled.hr`
	border:none;
	border-bottom:2px solid #000;
`
